/* eslint-disable */
/* tslint:disable */
/* tslint:disable comment-format */
/* eslint-disable no-alert, no-console */
/* eslint-disable react/jsx-pascal-case */

import React from 'react'
import cx from 'classnames'
import { DatePicker } from 'antd'
import { DatePickerProps } from 'antd/lib/date-picker'
import styled from 'styled-components'
import { RefProps } from '@lib/types'
import { default as lunarFormatter } from '@shared/utils/lunarFormatter'
import moment from 'moment'
import en from 'antd/es/date-picker/locale/en_US'

type Props = DatePickerProps & RefProps

const StyledDatePicker = styled(DatePicker)`
  &.ant-picker {
    border-radius: 4px;
  }
`

const getLunarDate = (current: any) => {
  let year = moment(current).format('YYYY') * 1
  let month = moment(current).format('M') * 1
  let day = moment(current).format('D') * 1
  return lunarFormatter.solar2lunar(year, month, day)
}

const buddhistLocale: typeof en = {
  ...en,
  lang: {
    ...en.lang,
    monthBeforeYear: false,
  },
}

const DatePickerComponent = React.forwardRef<typeof DatePicker, Props>(
  ({ className = '', ...props }, ref) => {
    // const yearPickerRef = React.useRef(null);

    // React.useEffect(() => {
    //   const modifyYearPicker2 = () => {
    //     const antpickerdecadebtn: any = document.querySelector(
    //       '.ant-picker-decade-btn'
    //     )
    //     if (antpickerdecadebtn) {
    //       const _tttttttt = antpickerdecadebtn.textContent
    //       const [start, end] = _tttttttt.split('-')
    //       const _tmp = `${Number(start) - 1911}-${Number(end) - 1911}`
    //       antpickerdecadebtn.textContent = _tmp
    //     }
    //   }
    //   const yearPickerDom :any = yearPickerRef.current;
    //   if (yearPickerDom) {
    //     document.addEventListener('click', modifyYearPicker2)
    //   }
    //   return () => {
    //     if (yearPickerDom) {
    //       document.removeEventListener('click', modifyYearPicker2);
    //     }
    //   };

    // }, [])

    return (
      <StyledDatePicker
        // forwardRef={ref}
        // ref={yearPickerRef}
        id={'lunarDatePicker'}
        className={cx('c-datepicker', className)}
        placeholder=""
        locale={buddhistLocale}
        // format={(v)=>{return moment().format(`${moment(v).year()-1911}/MM/DD`)}}
        {...props}
        dateRender={(current, today) => {
          return (
            <>
              <div className="lunar-date-picker">
                {moment(current).format('YYYY-MM-DD') !=
                moment(today).format('YYYY-MM-DD') ? (
                  <div className="ant-picker-cell-inner">
                    {moment(current).format('D')}
                  </div>
                ) : (
                  <div className="ant-picker-cell-inner">
                    {moment(current).format('D')}
                  </div>
                )}
                {getLunarDate(current)['lunarFestival'] ? (
                  <div
                    style={{ fontSize: '10px', opacity: 0.6, color: '#f90' }}>
                    {getLunarDate(current)['lunarFestival']}
                  </div>
                ) : getLunarDate(current)['festival'] ? (
                  <div
                    style={{ fontSize: '10px', opacity: 0.6, color: '#f90' }}>
                    {getLunarDate(current)['festival']}
                  </div>
                ) : getLunarDate(current)['Term'] ? (
                  <div
                    style={{ fontSize: '10px', opacity: 0.6, color: '#000' }}>
                    {getLunarDate(current)['Term']}
                  </div>
                ) : (
                  <div style={{ fontSize: '10px', opacity: 0.6 }}>
                    {getLunarDate(current)['IMonthCn']}
                    {getLunarDate(current)['IDayCn'] == '初一' &&
                    getLunarDate(current)['IMonthCn'].indexOf('闰') > -1
                      ? getLunarDate(current)['IMonthCn']
                      : getLunarDate(current)['IDayCn']}
                  </div>
                )}
              </div>
            </>
          )
        }}
      />
    )
  }
)

DatePickerComponent.displayName = 'DatePicker'

export default DatePickerComponent
