const RightDoubleArrow = ({ ...props }) => {
  return (
    <i className="icon-RightDoubleArrow" {...props}>
      <svg
        width="96"
        height="96"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11 19L17 12L11 5"
          stroke="#1C274C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          opacity="0.5"
          d="M6.99976 19L12.9998 12L6.99976 5"
          stroke="#1C274C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </i>
  )
}

export default RightDoubleArrow
