import { useTranslation } from 'react-i18next'
import { Select, SelectProps } from '@shared/innmaxUI/Form/Select'

type Props = {
  showAll?: boolean
} & SelectProps

export default function BobeeLightTypeSelect({
  showAll = false,
  ...props
}: Props) {
  const { t } = useTranslation()

  const data = [
    { desc: '光明燈', value: '光明燈' },
    { desc: '太歲燈', value: '太歲燈' },
    { desc: '龍山寺-光明燈', value: '龍山寺-光明燈' },
  ]

  return (
    <Select allowClear {...props}>
      {showAll && (
        <Select.Option key="all" value="">
          {t('common:all')}
        </Select.Option>
      )}
      {data.map(x => (
        <Select.Option key={x.value} value={x.value}>
          {x.desc}
        </Select.Option>
      ))}
    </Select>
  )
}
