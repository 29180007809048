import { useTranslation } from 'react-i18next'
import { Select, SelectProps } from '@shared/innmaxUI/Form/Select'

type Props = {
  showAll?: boolean
} & SelectProps

export default function BobeeLightLevelSelect({
  showAll = false,
  ...props
}: Props) {
  const { t } = useTranslation()

  const data = [
    { desc: '燈首', value: '燈首' },
    { desc: '一般燈', value: '一般燈' },
  ]

  return (
    <Select allowClear {...props}>
      {showAll && (
        <Select.Option key="all" value="">
          {t('common:all')}
        </Select.Option>
      )}
      {data.map(x => (
        <Select.Option key={x.value} value={x.value}>
          {x.desc}
        </Select.Option>
      ))}
    </Select>
  )
}
