const BobeeLight = ({ ...props }) => {
  return (
    <i className="icon-BobeeLight" {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_27_69474)">
          <path
            d="M16 15H4C2.34315 15 1 16.3431 1 18V19H19V18C19 16.3431 17.6569 15 16 15Z"
            stroke="#878787"
            stroke-width="1.4"
            stroke-miterlimit="10"
          />
          <path
            d="M14 9.04498C14 11.2284 12.2082 13 10 13C7.79176 13 6 11.2284 6 9.04498C6 6.86157 10 2 10 2C10 2 14 6.8586 14 9.04498Z"
            stroke="#878787"
            stroke-width="1.4"
            stroke-miterlimit="10"
          />
          <path
            d="M10 8V14"
            stroke="#878787"
            stroke-width="1.4"
            stroke-linecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_27_69474">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </i>
  )
}

export default BobeeLight
