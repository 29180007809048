import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select, SelectProps } from '@shared/innmaxUI/Form/Select'

export const BobeeLightStatusSelect = React.forwardRef<
  typeof Select,
  SelectProps
>(({ getFieldDecorator, initialValue, ...props }: any, ref): any => {
  const { t } = useTranslation()
  
  const data = [
    { desc: '未點燈', value: 0 },
    { desc: '已點燈', value: 1 },
  ]

  return (
    <Select forwardRef={ref} allowClear {...props}>
      <Select.Option key="all" value="">
        {t('common:all')}
      </Select.Option>
      {data.map(x => (
        <Select.Option key={x.value} value={x.value}>
          {x.desc}
        </Select.Option>
      ))}
    </Select>
  )
})

export default BobeeLightStatusSelect
