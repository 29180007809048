const Delete = ({ ...props }) => {
  return (
    <i className="icon-Delete" {...props}>
      <svg
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M69 39.75L51.5 57.25M51.5 39.75L69 57.25M38.242 24.9415C38.8898 24.3368 39.7428 24.0003 40.629 24H79.5C80.4283 24 81.3185 24.3687 81.9749 25.0251C82.6313 25.6815 83 26.5717 83 27.5V69.5C83 70.4283 82.6313 71.3185 81.9749 71.9749C81.3185 72.6313 80.4283 73 79.5 73H40.629C39.7428 72.9997 38.8898 72.6632 38.242 72.0585L13 48.5L38.242 24.9415Z"
          stroke="#4A4A4A"
          stroke-width="7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </i>
  )
}

export default Delete
