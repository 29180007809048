const LeftDoubleArrow = ({ ...props }) => {
  return (
    <i className="icon-LeftDoubleArrow" {...props}>
      <svg
        width="96"
        height="96"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 19L7 12L13 5"
          stroke="#1C274C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          opacity="0.5"
          d="M16.9998 19L10.9998 12L16.9998 5"
          stroke="#1C274C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </i>
  )
}

export default LeftDoubleArrow
