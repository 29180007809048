import React from 'react'
import { Select, SelectProps } from '@shared/Form/Select'
import { useBobeeLightCtrlerType } from '@services/device'

const BobeeLightCtrlerType = React.forwardRef<
  typeof Select,
  SelectProps<number>
>((props: SelectProps<number>, ref): any => {
  const { data, loading } = useBobeeLightCtrlerType()

  return (
    <Select ref={ref as any} loading={loading} allowClear {...props}>
      {data?.map((x: any, idx: number) => (
        <Select.Option key={idx} value={x.ctrlerType}>
          {x.ctrlerType}
        </Select.Option>
      ))}
    </Select>
  )
})

export default BobeeLightCtrlerType
