import DeviceStatusSelect from './DeviceStatusSelect'
import DeviceSelect from './DeviceSelect'
import GroupSelect from './GroupSelect'
import MultipleDistrict from './MultipleDistrict'
import RepairerSelect from './RepairerSelect'
import SessionSelect from './SessionSelect'
import RepairStateSelect from './RepairStateSelect'
import RepairCloseReasonSelect from './RepairCloseReasonSelect'
import DeviceDisplayNemeSelect from './DeviceDisplayNemeSelect'
import CameraTypeSelect from './CameraTypeSelect'
import SwitchBoxSelect from './SwitchBoxSelect'
import ControllerTypeSelect from './ControllerTypeSelect'
import SubDeviceSelect from './SubDeviceSelect'
import WeatherControllerTypeSelect from './WeatherControllerTypeSelect'
import PowerControllerTypeSelect from './PowerControllerTypeSelect'
import UserUsageStatusSelect from './UserUsageStatusSelect'
import ReportRangeTimeSelect from './ReportRangeTimeSelect'
import ReportDeviceSelect from './ReportDeviceSelect'
import ActiveStateSelect from './ActiveStateSelect'
import CloseReasonSelect from './CloseReasonSelect'
import RepairedReasonSelect from './RepairedReasonSelect'
import ReportReasonSelect from './ReportReasonSelect'
import SessionTreeSelect from './SessionTreeSelect'
import CameraCtrlerType from './CameraCtrlerType'
import EelectricitymeterControllerTypeSelect from './EelectricitymeterControllerTypeSelect'
import WaterMeterControllerTypeSelect from './WaterMeterControllerTypeSelect'
import BobeeLightLevelSelect from './BobeeLightLevelSelect'
import BobeeLightStatusSelect from './BobeeLightStatusSelect'
import BobeeLightTypeSelect from './BobeeLightTypeSelect'
import BobeeLightCtrlerType from './BobeeLightCtrlerType'

interface IOptionsSelect {
  DeviceSelect: typeof DeviceSelect
  DeviceStatusSelect: typeof DeviceStatusSelect
  DeviceDisplayNemeSelect: typeof DeviceDisplayNemeSelect
  GroupSelect: typeof GroupSelect
  SessionSelect: typeof SessionSelect
  MultipleDistrict: typeof MultipleDistrict
  RepairerSelect: typeof RepairerSelect
  RepairStateSelect: typeof RepairStateSelect
  RepairCloseReasonSelect: typeof RepairCloseReasonSelect
  CameraTypeSelect: typeof CameraTypeSelect
  SwitchBoxSelect: typeof SwitchBoxSelect
  ControllerTypeSelect: typeof ControllerTypeSelect
  SubDeviceSelect: typeof SubDeviceSelect
  WeatherControllerTypeSelect: typeof WeatherControllerTypeSelect
  PowerControllerTypeSelect: typeof PowerControllerTypeSelect
  UserUsageStatusSelect: typeof UserUsageStatusSelect
  ReportRangeTimeSelect: typeof ReportRangeTimeSelect
  ReportDeviceSelect: typeof ReportDeviceSelect
  ActiveStateSelect: typeof ActiveStateSelect
  CloseReasonSelect: typeof CloseReasonSelect
  RepairedReasonSelect: typeof RepairedReasonSelect
  ReportReasonSelect: typeof ReportReasonSelect
  SessionTreeSelect: typeof SessionTreeSelect
  CameraCtrlerType: typeof CameraCtrlerType
  EelectricitymeterControllerTypeSelect: typeof EelectricitymeterControllerTypeSelect
  WaterMeterControllerTypeSelect: typeof WaterMeterControllerTypeSelect
  BobeeLightLevelSelect: typeof BobeeLightLevelSelect
  BobeeLightStatusSelect: typeof BobeeLightStatusSelect
  BobeeLightTypeSelect: typeof BobeeLightTypeSelect
  BobeeLightCtrlerType: typeof BobeeLightCtrlerType
}

const OptionsSelect: IOptionsSelect = {
  DeviceSelect,
  DeviceStatusSelect,
  DeviceDisplayNemeSelect,
  GroupSelect,
  SessionSelect,
  MultipleDistrict,
  RepairerSelect,
  RepairStateSelect,
  RepairCloseReasonSelect,
  CameraTypeSelect,
  SwitchBoxSelect,
  ControllerTypeSelect,
  SubDeviceSelect,
  WeatherControllerTypeSelect,
  PowerControllerTypeSelect,
  UserUsageStatusSelect,
  ReportDeviceSelect,
  ReportRangeTimeSelect,
  ActiveStateSelect,
  CloseReasonSelect,
  RepairedReasonSelect,
  ReportReasonSelect,
  SessionTreeSelect,
  CameraCtrlerType,
  EelectricitymeterControllerTypeSelect,
  WaterMeterControllerTypeSelect,
  BobeeLightLevelSelect,
  BobeeLightStatusSelect,
  BobeeLightTypeSelect,
  BobeeLightCtrlerType
}

export default OptionsSelect
