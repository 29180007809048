import React from 'react'
import { path } from 'ramda'
import {
  request,
  useMakeMutation,
  MutationOptions,
} from '@shared/innmaxLib/services'
import { useQuery } from 'react-query'
import { useLazyQuery, QueryVariables } from '@shared/innmaxLib/services'
import { useConvertData } from '@services/device'
import { convertData } from '@shared/innmaxLib/services'


export const createBobeelight = (values: Partial<any>) => {
  const { id, ...others } = values
  return request(`/device/bobeelight/${id}`, { method: 'POST', body: others })
}

// 尋燈
export const useLocateBobeeLight = (options: MutationOptions = {}) => {
  return useMakeMutation(createBobeelight, options)
}

//控制器型號
export const useBobeeLightCtrlerType = () => {
  const { data, refetch, isLoading, ...others } = useQuery(
    [`/device/bobeelight/ctrler/types`],
    {
      select: (res: any) => path(['data'], res) as { ctrlerType: string }[],
    }
  )

  return {
    ...others,
    refetch,
    loading: isLoading,
    data,
  }
}

// testCMD
export const postTestCmd = (values: Partial<any>) => {
  return request(`/device/bobeelight/controller`, {
    method: 'POST',
    body: values,
  })
}

export const useBobeeLightTestCmd = (options: MutationOptions = {}) => {
  return useMakeMutation(postTestCmd, options)
}

//BobeeLightBindingMgmt
// 搜尋綁定清單
// isBind (true/false): boolean
export const useBobeeLightBind = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const { data, refetch, ...others } = useLazyQuery(
    [`/device/bobeelight/bind`, { ...variables }],
    {
      ...options,
      keepPreviousData: false,
    }
  )

  const {
    sessionName,
  } = useConvertData()

  const dataSource = React.useMemo(
    () =>
      convertData<any>({
        converter: x => {
          return {
            ...x,
            ...sessionName(x),
            properties: {
              ...x.properties,
            },
          }
        },
      })(data),
    [data]
  )

  return {
    ...others,
    dataSource,
    queryBobeeLightBind: refetch,
  }
}

// 綁定光明燈
export const createBind = (values: Partial<any>) =>
  request('/device/bobeelight/bind/{ids}', { method: 'POST', body: values })
export const useBobeeLightCreateBind = (options: MutationOptions = {}) => {
  return useMakeMutation(createBind, options)
}

// 更新綁定光明燈 <=更新祈福語
export const editBind = (values: Partial<any>) =>
  request('/device/bobeelight/bind/{ids}', { method: 'PUT', body: values })

export const useBobeeLightEditBind = (options: MutationOptions = {}) => {
  return useMakeMutation(editBind, options)
}
